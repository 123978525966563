<template>
    <div>
        <div class="veadmin-iframe-header veadmin-iframe-header-custom-fixed">
            <span>当前位置：</span>
            资料管理
            <span>&gt;</span>
            管理笔记
            <span>&gt;</span>
            增加笔记
            <button class="veadmin-iframe-button" onclick="history.back();">
                <i class="el-icon-back"></i>
            </button>
            <button class="veadmin-iframe-button" onclick="location.reload()">
                <i class="el-icon-refresh-right"></i>
            </button>
        </div>
        <div class="veadmin-iframe-content veadmin-iframe-content-custom-fixed">
            <el-form :model="note" status-icon :rules="rules" label-width="100px" style="margin: 20px">
                <el-form-item label="课题：" prop="topic">{{ this.$route.query.topicTitle }}</el-form-item>
                <el-form-item label="章节：" prop="chapter">{{ this.$route.query.chapterTitle }}</el-form-item>
                <el-form-item label="标题：" prop="title">
                    <el-input v-model="note.title" placeholder="请输入标题"></el-input>
                </el-form-item>
                <el-form-item label="" prop="text">
                    <div id="editor"></div>
                </el-form-item>
                <el-form-item label="设置排序：" prop="sort">
                    <el-input style="width: 220px" v-model.number="note.sort" placeholder="请输入数字，数字越小越靠前"></el-input>
                </el-form-item>
                <el-form-item style="margin-top: 20px">
                    <el-button type="primary" round @click="submitForm(1)">发布笔记</el-button>
                    <!-- <el-button type="info" round @click="submitForm(0)">保存为草稿</el-button> -->
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
// 引入 wangEditor
import wangEditor from "wangeditor";
//代码高亮
import hljs from "highlight.js";
import "highlight.js/styles/googlecode.css";
import base from "@/base.js";
import { apiAddNote } from "@/api/admin/note.js";

export default {
    data() {
        var checkEmpty = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("不能为空,请输入内容"));
            } else {
                callback();
            }
        };
        return {
            note: {
                title: "",
                text: "",
                sort: ""
            },
            rules: {
                title: [{ validator: checkEmpty, trigger: "blur" }],
                text: [{ validator: checkEmpty, trigger: "blur" }]
            }
        };
    },
    methods: {
        submitForm(status) {
            //console.log(this.chooseCategory);
            //去除首尾空格
            this.note.title = this.note.title.trim();

            var formData = new FormData();
            formData.append("chapterId", this.$route.query.chapterId);
            formData.append("title", this.note.title);
            formData.append("text", this.note.text);
            formData.append("sort", this.note.sort == "" ? 0 : this.note.sort);
            formData.append("status", status);
            /* for (var [a, b] of formData.entries()) {
				console.log(a + ":", b || '""');
			} */

            //调用接口 保存笔记
            apiAddNote(this.$store.state.token, formData).then((response) => {
                if (response.data.code === 200) {
                    this.$message({
                        message: "保存成功！",
                        type: "success"
                    });
                    //执行成功1秒后跳转页面
                    setTimeout(function () {
                        window.location.href = "/admin/note/list";
                    }, 1000);
                } else {
                    console.log("response>>", response);
                    this.$message.error(response.data.message);
                }
            });
        }
    },
    mounted() {
        //生成编辑器
        const editor = new wangEditor("#editor");
        //上传文件的名字
        editor.config.uploadFileName = "image";
        // 配置 server 接口地址
        editor.config.uploadImgServer = base.apiServer + "/api/1/admin/note/upload_image";
        // 1M
        editor.config.uploadImgMaxSize = 1024 * 1024;
        //限制上传格式
        editor.config.uploadImgAccept = ["jpg", "jpeg", "png", "gif", "bmp"];
        // 一次最多上传 5 个图片
        editor.config.uploadImgMaxLength = 5;
        //设置headers
        editor.config.uploadImgHeaders = { token: this.$store.state.token };
        //设置参数
        // editor.config.uploadImgParams = { type: "note" };
        //设置z-index
        editor.config.zIndex = 500;
        //默认编辑器占高300px,设置为320
        editor.config.height = 320;
        // 配置 onchange 回调函数，将数据同步到 vue 中
        editor.config.onchange = (newHtml) => {
            //console.log(newHtml);
            this.note.text = newHtml;
        };
        // 挂载highlight插件
        editor.highlight = hljs;
        // 创建编辑器
        editor.create();
        this.editor = editor;
    },
    beforeDestroy() {
        // 销毁编辑器
        this.editor.destroy();
        this.editor = null;
    }
};
</script>
